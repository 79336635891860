<template>
    <div id="webviewer" ref="viewer"></div>
</template>

<script>

    import { ref, onMounted } from 'vue'
    import WebViewer from '@pdftron/webviewer'

    export default {
        name: 'webviewer',
        props: {initialDoc: {type: String}, userInfo: {type: Object}},
        setup(props) {

            const viewer = ref(null);
            onMounted(() => {
                console.log(props.userInfo);

                const path = `${process.env.BASE_URL}webviewer`;
                WebViewer({path, initialDoc: props.initialDoc}, viewer.value)
                .then(instance => {
                    // Disable Specific toolbarGroup
                    instance.disableElements(['toolbarGroup-Edit']);
                    instance.disableElements(['toolbarGroup-Insert']);
                    instance.disableElements(['searchButton']);
                    // instance.disableElements(['notesPanel']);
                    instance.disableElements(['menuButton']);
                    //instance.disableElements(['ribbons']);
                    //instance.disableElements(['toolsHeader']);

                    const {docViewer, annotManager } = instance;
                    annotManager.setCurrentUser(props.userInfo.name);
                    annotManager.setIsAdminUser(props.userInfo.isAdmin);
                    // docViewer.setWatermark({
                    //     // Draw diagonal watermark in middle of the document
                    //     diagonal: {
                    //         fontSize: 25, // or even smaller size
                    //         fontFamily: 'sans-serif',
                    //         color: 'red',
                    //         opacity: 50, // from 0 to 100
                    //         text: 'watermark'
                    //     },

                    //     // Draw header watermark
                    //     // header: {
                    //     //     fontSize: 10,
                    //     //     fontFamily: 'sans-serif',
                    //     //     color: 'red',
                    //     //     opacity: 70,
                    //     //     left: 'left watermark',
                    //     //     center: 'center watermark',
                    //     //     right: ''
                    //     // }
                    // });


                    instance.setHeaderItems(header => {
                        header.push({
                            type: 'actionButton',
                            img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/></svg>',
                            onClick: async () => {
                                const doc = docViewer.getDocument();
                                const xfdfString = await annotManager.exportAnnotations();
                                const data = await doc.getFileData({
                                    // saves the document with annotations in it
                                    xfdfString
                                });
                                const arr = new Uint8Array(data);
                                const blob = new Blob([arr], { type: 'application/pdf' });
                                let file = new File([blob], "mydoc.pdf", { type: 'application/pdf' });
                                let container = new DataTransfer();
                                container.items.add(file);
                                document.getElementById("pdf_data").files = container.files;                                
                                document.getElementById("fileAddMsg").style.display = "block";                                
                                document.getElementById("fileAddMsg").innerHTML = "Annotated PDF file add for Submitted!";                                

                                setTimeout(() =>{                                
                                document.getElementById("fileAddMsg").style.display = "none";
                                    document.getElementById("fileAddMsg").innerHTML = '';
                                }, 2000);

                            }

                        });
                    });
                });
            });

            return { viewer }

        },
        methods: {
            /*saveData()  {
                
                var data = document.getElementById("pdf_data");

                let file = data.files[0];

               // alert(`File name: ${file.name}`); // e.g my.png
                //alert(`Last modified: ${file.size}`); // e.g 1552830408824

                //const arr = new Uint8Array(file);
                const blob = new Blob([file], { type: 'application/pdf' });

                const headers = new Headers();
                headers.append('Access-Control-Allow-Origin', 'http://localhost:80');
                headers.append('Access-Control-Allow-Credentials', 'true');
                const file_data = new FormData();
                file_data.append('mydoc.pdf', blob, 'mydoc.pdf');
                file_data.append('email', "akbaronlinebd.com");
                file_data.append('phone', "018366282828");

                // depending on the server, 'FormData' might not be required and can just send the Blob directly

                const req = new XMLHttpRequest();
                req.open("POST", 'http://127.0.0.1//test_pdf_upload/pdf_upload.php', true);
                //req.open("POST", 'https://google.com', true);

                req.onload = function (oEvent) {
                    console.log(oEvent);
                };
                req.onreadystatechange = function() {
                    if(req.readyState == 4 && req.status == 200) {
                        //var address = JSON.parse();
                        console.log("IP: ", req.responseText);
                    }
                }
                req.send(file_data);
                

            }*/
        }

    }



</script>

<style>

    #webviewer {
        height: 800px;
    }

</style>