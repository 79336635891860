<template>
  <!--<img alt="Vue logo" src="./assets/logo.png">-->
  
  <!-- <WebViewer initialDoc="" /> -->

  <div class="container">
    <div class="row">
      <div class="col-md-12" v-if="validationError"> 
        <div class="alert alert-danger" role="alert">{{ validationError }}</div>
      </div>
      <div class="col-md-12" v-if="successMsg"> 
        <div class="alert alert-success" role="alert">{{ successMsg }}</div>
      </div>
      <div class="col-md-12"> 
        <div id="fileAddMsg" class="alert alert-success" role="alert" style="display:none;"></div>
      </div>

      <div class="col-md-9"> <div v-if="showWebViewer"><WebViewer :initialDoc="pdfUrl" :userInfo="user_obj"></WebViewer> </div></div>
      <div class="col-md-3"> 
        <input type="file" id="pdf_data" />

        <div v-if="loading_question">
          <div class="row">
            <div class="col-md-6 col-sm-6 qmark" v-for="(question,n) in question_data"  :key="n">
              <!-- <input v-model.number="age" type="number" /> -->
                <div class="row">
                  <div class="col-md-4 col-sm-4 qbtn">
                    <button class="btn btn-success btn-sm" @click="getQuestion(question.question)">Q{{ n+1 }}</button>
                  </div>
                  <div class="col-md-4 col-sm-4">
                    <p>{{ question.question_marks }}</p>
                  </div>
                  <div class="col-md-4 col-sm-4">
                    <input type="text" class="form-control" @keyup="changeMarks(n)" @focus="getMarkVal(n)" v-model="question.question_remarks">
                  </div>
                </div>
              
            </div>

            <div class="col-md-12 all_mark">
              <div class="row">
                <label for="total_mark" class="col-md-6">Total Marks</label>
                <div class="col-md-6">
                  <input type="text" id="total_mark" class="form-control" :value="totalMarks">
                </div>
              </div>
            </div>

            <div class="col-md-12 question">
              <div class="row">
                <label for="question" class="col-md-12">Question</label>
                <div class="col-md-12">
                  <div id="question_view" v-html="v_question"></div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="row">
          <button id="save_mark" class="btn btn-primary btn-block" @click="saveData">Submit</button>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>

const urlParams = new URLSearchParams(window.location.search);
const steid = urlParams.get('steid');
const uid = urlParams.get('uid');
const type = urlParams.get('type');

const headers = new Headers();
headers.append('Access-Control-Allow-Origin', 'http://localhost:80');
headers.append('Access-Control-Allow-Credentials', 'true');


import WebViewer from './components/WebViewer.vue';
export default {
  name: 'App',
  components: {
    WebViewer
  },
  data() {
    return {
        //initialDoc  : steid, 
        // pdfUrl      : "http://pdftron.s3.amazonaws.com/downloads/pl/demo-annotated.pdf",
        //pdfUrl      : "http://localhost/icon_plus/upload/"+ pdf_folder +"/"+pdf_file,
        pdfUrl      : '',
        user_obj    : {
          name: '',
          isAdmin: true,
        },
        stexam_data : '',
        question_data: [],
        question_old_mark: '',
        loading_question : false,
        showWebViewer : false,
        errored     : false,
        validationError: false,
        successMsg: '',
        v_question  : '',
        total_marks : 0,
        form_data   : {
          student_exam_id : '',
          exam_id         : '',
          student_id      : '',
          old_file        : '',
          written_answer_check_files: '',
          written_marks   : '',
          answer_question : [],
        }

    }
  },
  created() {
    this.getAllData();
    setTimeout(()=> {
      this.showWebViewer = true;
    }, 1000);
  },
  computed: {
      totalMarks(){
        var total = 0;
        this.question_data.forEach(question=>{
            total += Number(question.question_remarks);
        });
        return total;
      }
  },
  mounted() {
    this.total_marks = this.totalMarks;
  },
  methods: {
    
    getAllData() {
      var url = '';
      if(type == 1) {
        // url = "http://localhost/iconplus_update/api/Annotation_api/showData/"+steid +"/"+uid
        url = "https://iconplus.com.bd/api/Annotation_api/showData/"+steid +"/"+uid
      }else{
        // url = "http://localhost/iconplus_update/api/Annotation_api/showDataTeacher/"+steid +"/"+uid
        url = "https://iconplus.com.bd/api/Annotation_api/showDataTeacher/"+steid +"/"+uid
      }
      this.axios
      .get(url)
      .then(response => {
        this.stexam_data = response.data.student_exam_data;
        this.question_data = response.data.written_questions; 
        this.pdfUrl = response.data.student_exam_data.file_url; 
        this.user_obj.name = response.data.user_data.name; 
        
        this.loading_question = true;

        console.log(response.data);
      })
      .catch(error => {
        console.log(error)
        this.errored = true
      });

    },
    getQuestion(question) {
        this.v_question = question;
        this.$nextTick(function() {
            window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, "mjx"]);
        });
    },
    getMarkVal(key){
        this.question_old_mark = this.question_data[key].question_remarks;
    },
    changeMarks(id) {
      console.log(this.question_data[id]);
      if(Number(this.question_data[id].question_marks) >= Number(this.question_data[id].question_remarks)) {
        this.validationError = false;
        return true;
        
      }else{
        this.validationError = "This question marks can't getter then "+this.question_data[id].question_marks;
        this.question_data[id].question_remarks = this.question_old_mark;
        setTimeout(()=> {
            this.validationError = false;
          }, 2000);
        return false;
      }
    }, 
    saveData(){

      let obj = this.form_data;
      var formData = this.formData(obj);

      if(this.errored == false) {
        this.axios
        // .post("http://localhost/iconplus_update/api/Annotation_api/saveData", formData)
        .post("https://iconplus.com.bd/api/Annotation_api/saveData", formData)
        .then(response => {
          console.log(response.data);
          this.successMsg = response.data.msg;
          setTimeout(()=> {
            this.successMsg = '';
            window.location.reload();
          }, 2000);

        })
        .catch(error => {
          console.log(error)
          this.errored = true
        });

      }


    },
    formData(obj){

      var data = document.getElementById("pdf_data");
      var dataValue = data.value;
      let file = data.files[0];

      if(dataValue == "") {
        this.validationError = "Please First Annotated file Save";
        this.errored = true;
        setTimeout(()=> {
            this.validationError = false;
          }, 2000);
        return false;
      }else{

        this.validationError = false;
        this.errored = false;
        obj.student_exam_id = this.stexam_data.student_exam_id;
        obj.exam_id = this.stexam_data.exam_id;
        obj.student_id = this.stexam_data.student_id;
        obj.old_file  = this.stexam_data.file_name
        obj.written_answer_check_files = file;
        obj.written_marks = this.totalMarks;
        obj.answer_question = [];
        let single_question = "";
        for(let i=0; i<this.question_data.length; i++) {
          
          if(this.question_data[i].question_remarks != '') {
              single_question = {
                "question_id": this.question_data[i].id,
                "question_remarks": this.question_data[i].question_remarks,

              }
              obj.answer_question.push(single_question);
          }
        }

        obj.answer_question = JSON.stringify(obj.answer_question);
        var formData = new FormData();
        for ( var key in obj ) {
            formData.append(key, obj[key]);
        }
        return formData;
      }
    }
    



  }

}

</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }

  #pdf_data{ 
    display: none;
  }
  .qmark {
    margin-bottom: 5px;
  }

  /* .qmark.col-md-6 {
    padding: 0px 5px;
  } */

  .qmark .col-md-4, .all_mark .col-md-6, .question .col-md-12 {
    padding: 0px;
  }

  .qmark .col-md-6.qbtn {
    text-align: right;
    padding-right: 10px;
  }

  .qmark .col-md-4 .form-control{
    height: calc(1.1em + 0.75rem + 2px);
    line-height: 1;
  }

  .qmark .col-md-4 p{
    height: calc(1.1em + 0.75rem + 2px);
    margin: 0;
    vertical-align: middle;
    padding-top: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .all_mark {
    margin-top: 15px;
  }

  .all_mark label {
    margin-bottom: 0;
    line-height: 2.5;
  }

  .question {
    border: 2px dashed #28a745;
    margin-top: 15px;
  }

  .question .row{
    padding: 10px 10px;
  }
  .question label {
    text-align: left;
  }
  #question_view {
    border: 1px solid #ccc;
    background: #eee;
    border-radius: 4px;
    padding: 10px;
    text-align: left;
    min-height: 60px;
  }
  #save_mark {
    margin-top: 10px;
  }
</style>
